const data = {
  recent: [
    {
      id: 9,
      name: '炭焼珈琲',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
      description:
        '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
      map_position_id: 11,
      taste_parameter: {
        rich: 4,
        bitter: 4,
        acidity: 2
      },
      link_url: 'https://drip-pod.jp/products/1374',
      order: 992,
      recommend: false,
      is_list_publishable: false,
      is_publishable: true,
      publish_start_at: '2023-01-07 00:00:00',
      publish_end_at: '2024-01-07 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 4,
      name: 'モカ ＆ キリマンジァロ',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
      description:
        'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
      map_position_id: 7,
      taste_parameter: {
        rich: 3,
        bitter: 2,
        acidity: 4
      },
      link_url: 'https://drip-pod.jp/products/417',
      order: 997,
      recommend: true,
      is_list_publishable: true,
      is_publishable: true,
      publish_start_at: '2023-01-12 00:00:00',
      publish_end_at: '2024-01-12 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 1,
      name: 'ブルーマウンテンブレンド',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-4.png',
      description:
        '苦味・酸味・コクが見事に調和し、ブラウンシュガーのような上品な甘みが特長。コーヒーの王様の名にふさわしい、品格のある味わい。',
      map_position_id: 9,
      taste_parameter: {
        rich: 3,
        bitter: 3,
        acidity: 3
      },
      link_url: 'https://drip-pod.jp/products/1368',
      order: 1000,
      recommend: false,
      is_list_publishable: true,
      is_publishable: true,
      publish_start_at: '2023-01-15 00:00:00',
      publish_end_at: null,
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    }
  ],
  recommend: [
    {
      id: 4,
      name: 'モカ ＆ キリマンジァロ',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
      description:
        'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
      map_position_id: 7,
      taste_parameter: {
        rich: 3,
        bitter: 2,
        acidity: 4
      },
      link_url: 'https://drip-pod.jp/products/417',
      order: 997,
      recommend: true,
      is_list_publishable: false,
      is_publishable: true,
      publish_start_at: '2023-01-12 00:00:00',
      publish_end_at: '2024-01-12 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 5,
      name: 'グァテマラ & コロンビア',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-1.png',
      description:
        '中南米で育った相性の良いコーヒー豆をブレンド。 グァテマラのカカオのような濃厚感と、コロンビアのやわらかい酸味がマッチ。 \nボディがしっかりしているので、飲みごたえがありながらすっきりとした飲み口をお楽しみいただけます。',
      map_position_id: 20,
      taste_parameter: {
        rich: 4,
        bitter: 3,
        acidity: 3
      },
      link_url: 'https://drip-pod.jp/products/418',
      order: 996,
      recommend: true,
      is_list_publishable: false,
      is_publishable: true,
      publish_start_at: '2023-01-11 00:00:00',
      publish_end_at: '2024-01-11 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 6,
      name: 'マンデリン & ブラジル',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-0.png',
      description:
        'アジアと南米のコラボレーションブレンド。 ドライフルーツのような甘み、力強くコク深い味わい。',
      map_position_id: 23,
      taste_parameter: {
        rich: 4,
        bitter: 4,
        acidity: 3
      },
      link_url: 'https://drip-pod.jp/products/1373',
      order: 995,
      recommend: true,
      is_list_publishable: true,
      is_publishable: true,
      publish_start_at: '2023-01-10 00:00:00',
      publish_end_at: '2024-01-10 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    },
    {
      id: 7,
      name: '有機栽培コロンビア',
      image_url:
        'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-12.png',
      description:
        'コロンビアで丁寧に有機栽培されたコーヒー豆を１００％使用。自然の恵みが詰まった芳醇な味わいです。',
      map_position_id: 25,
      taste_parameter: {
        rich: 4,
        bitter: 3,
        acidity: 4
      },
      link_url: 'https://drip-pod.jp/products/426',
      order: 994,
      recommend: true,
      is_list_publishable: true,
      is_publishable: true,
      publish_start_at: '2023-01-09 00:00:00',
      publish_end_at: '2024-01-09 00:00:00',
      created_at: '2023-02-02T03:39:03.000000Z',
      updated_at: '2023-02-02T03:39:03.000000Z'
    }
  ],
  capsules: {
    D: [
      {
        id: 6,
        name: 'マンデリン & ブラジル',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-0.png',
        description:
          'アジアと南米のコラボレーションブレンド。 ドライフルーツのような甘み、力強くコク深い味わい。',
        map_position_id: 23,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1373',
        order: 995,
        recommend: true,
        is_list_publishable: false,
        is_publishable: true,
        publish_start_at: '2023-01-10 00:00:00',
        publish_end_at: '2024-01-10 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 8,
        name: 'カフェインレスコーヒー',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-11.png',
        description:
          'おいしさはそのままに、カフェインをカット。豊かな香りとマイルドな味わい。',
        map_position_id: 29,
        taste_parameter: {
          rich: 2,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1375',
        order: 993,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-08 00:00:00',
        publish_end_at: '2024-01-08 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 12,
        name: 'アイスコーヒー',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-7.png',
        description:
          'ダークチョコレートのような滑らかさとすっきりとしたコクのある味わい。 より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 36,
        taste_parameter: {
          rich: 5,
          bitter: 5,
          acidity: 1
        },
        link_url: 'https://drip-pod.jp/products/1372',
        order: 989,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-04 00:00:00',
        publish_end_at: '2024-01-04 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      }
    ],
    C: [
      {
        id: 5,
        name: 'グァテマラ & コロンビア',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-1.png',
        description:
          '中南米で育った相性の良いコーヒー豆をブレンド。 グァテマラのカカオのような濃厚感と、コロンビアのやわらかい酸味がマッチ。 \nボディがしっかりしているので、飲みごたえがありながらすっきりとした飲み口をお楽しみいただけます。',
        map_position_id: 20,
        taste_parameter: {
          rich: 4,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/418',
        order: 996,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-11 00:00:00',
        publish_end_at: '2024-01-11 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 7,
        name: '有機栽培コロンビア',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-12.png',
        description:
          'コロンビアで丁寧に有機栽培されたコーヒー豆を１００％使用。自然の恵みが詰まった芳醇な味わいです。',
        map_position_id: 25,
        taste_parameter: {
          rich: 4,
          bitter: 3,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/426',
        order: 994,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-09 00:00:00',
        publish_end_at: '2024-01-09 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 11,
        name: '鑑定士の誇り リッチブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-9.png',
        description:
          'なじみがよく、ローストしたナッツのような香ばしさとコクのある味わい。より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 27,
        taste_parameter: {
          rich: 4,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1371',
        order: 990,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-05 00:00:00',
        publish_end_at: '2024-01-05 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 13,
        name: '静岡まろみ焙じ茶',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-6.png',
        description:
          '世界農業遺産 静岡の茶草場農法で育てられた、静岡県掛川市産の茶葉を使用。 芳しい香りとともに、お茶本来のまろやかな甘みを感じられる味わい。',
        map_position_id: 31,
        taste_parameter: {
          rich: 5,
          bitter: 5,
          acidity: 5
        },
        link_url: 'https://drip-pod.jp/products/1006',
        order: 988,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-03 00:00:00',
        publish_end_at: '2024-01-03 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 15,
        name: '深蒸し静岡煎茶',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-14.png',
        description:
          '世界農業遺産の茶草場農法で育てられた茶葉を使用。 深蒸しで引き出した甘みとうまみのある味わい。',
        map_position_id: 21,
        taste_parameter: {
          rich: 1,
          bitter: 1,
          acidity: 1
        },
        link_url: 'https://drip-pod.jp/products/423',
        order: 986,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-01 00:00:00',
        publish_end_at: '2023-01-01 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      }
    ],
    B: [
      {
        id: 3,
        name: 'ホンジュラス & コロンビア',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-5.png',
        description:
          'レインフォレスト・アライアンス認証農園産コーヒーを100%使用。 持続可能な農法によって育まれた、風味豊かな味わい。 \nピーチのような長く続く甘い余韻とまろやかなコクのある味わい。',
        map_position_id: 17,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1376',
        order: 998,
        recommend: false,
        is_list_publishable: false,
        is_publishable: true,
        publish_start_at: '2023-01-13 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 9,
        name: '炭焼珈琲',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-10.png',
        description:
          '炭火で丹念に焙煎したスモーキーな香りとコク深く、甘い余韻のある味わい。',
        map_position_id: 11,
        taste_parameter: {
          rich: 4,
          bitter: 4,
          acidity: 2
        },
        link_url: 'https://drip-pod.jp/products/1374',
        order: 992,
        recommend: false,
        is_list_publishable: false,
        is_publishable: true,
        publish_start_at: '2023-01-07 00:00:00',
        publish_end_at: '2024-01-07 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 10,
        name: '鑑定士の誇り スペシャルブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-8.png',
        description:
          'なじみがよく、メープルシロップのような甘みのある、まろやかな味わい。より良い地球環境、より良い生産者の生活に繋がるよう、サステナブルに調達されたコーヒー豆を50%以上使用しています。',
        map_position_id: 4,
        taste_parameter: {
          rich: 2,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1370',
        order: 991,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-06 00:00:00',
        publish_end_at: '2024-01-06 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      }
    ],
    A: [
      {
        id: 1,
        name: 'ブルーマウンテンブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-4.png',
        description:
          '苦味・酸味・コクが見事に調和し、ブラウンシュガーのような上品な甘みが特長。コーヒーの王様の名にふさわしい、品格のある味わい。',
        map_position_id: 9,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/1368',
        order: 1000,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-15 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 2,
        name: 'ハワイコナブレンド',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-3.png',
        description:
          'トロピカルフルーツのような明るい酸味が特長。 南国の雰囲気を感じさせる、爽やかで透明感のある味わい。',
        map_position_id: 2,
        taste_parameter: {
          rich: 2,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/1369',
        order: 999,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-14 00:00:00',
        publish_end_at: null,
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 4,
        name: 'モカ ＆ キリマンジァロ',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
        description:
          'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
        map_position_id: 7,
        taste_parameter: {
          rich: 3,
          bitter: 2,
          acidity: 4
        },
        link_url: 'https://drip-pod.jp/products/417',
        order: 997,
        recommend: true,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-12 00:00:00',
        publish_end_at: '2024-01-12 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      },
      {
        id: 14,
        name: 'アールグレイ紅茶',
        image_url:
          'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-13.png',
        description:
          'リーフティー特有の豊かな香り。柑橘系ベルガモットの爽やかな香りと紅茶のまろやかさが調和したフレーバーティー。 ホットでもアイスでもお楽しみいただけます。',
        map_position_id: 15,
        taste_parameter: {
          rich: 3,
          bitter: 3,
          acidity: 3
        },
        link_url: 'https://drip-pod.jp/products/424',
        order: 987,
        recommend: false,
        is_list_publishable: true,
        is_publishable: true,
        publish_start_at: '2023-01-02 00:00:00',
        publish_end_at: '2024-01-02 00:00:00',
        created_at: '2023-02-02T03:39:03.000000Z',
        updated_at: '2023-02-02T03:39:03.000000Z'
      }
    ]
  }
};

export { data };
