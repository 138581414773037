const data = {
  id: 1,
  name: 'はちみつしょうがコーヒー',
  appeal_word:
    '体の芯までホッとあたたまる、はちみつと生姜を使ったホットコーヒードリンク♪ぜひお試しください！',
  image_url:
    'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/dp-recipe/test09_m.png',
  description:
    '体の芯までホッとあたたまる、はちみつと生姜を使ったホットコーヒードリンク♪ぜひお試しください！',
  map_position_id: 32,
  map_comment:
    '体の芯までホッとあたたまる、はちみつと生姜を使ったホットコーヒードリンク♪ぜひお試しください！',
  message:
    '体の芯までホッとあたたまる、はちみつと生姜を使ったホットコーヒードリンク♪\nぜひお試しください！',
  dp_capsule_id: 4,
  barista_id: 8,
  required_time: '',
  recipe: [
    {
      material: 'しょうが',
      quantity: '小さじ1/4'
    },
    {
      material: 'はちみつまたは砂糖',
      quantity: '小さじ1'
    }
  ],
  process: [
    'ドリップポッドでメモリを４にして、コーヒーを抽出します。',
    'はちみつや砂糖の分量はお好みで調整してください。'
  ],
  step_message: {
    extract1: '',
    extract2: '',
    steaming: ''
  },
  extract_parameter: {
    pre_time: 12,
    all_value: 166,
    pre_value: 12,
    back_value: 83,
    front_value: 83,
    back_pump_pwm: 80,
    front_pump_pwm: 80,
    back_temperature: 83,
    front_temperature: 83
  },
  additional_images: [],
  is_publishable: true,
  publish_start_at: '2023-01-09 00:00:00',
  publish_end_at: '2024-01-09 00:00:00',
  created_at: '2023-02-02T03:39:03.000000Z',
  updated_at: '2023-02-02T03:39:03.000000Z',
  barista: {
    id: 8,
    name: '早川 契史',
    name_alphabetical: 'Hisashi Hayakawa',
    overview:
      '・UCCコーヒーアドバイザー・UCCコーヒー抽出士\n・(アメリカ)CQI認定Qアラビカグレーダー\n・(コロンビア)マイルドコーヒークオリティコントロールスペシャリスト',
    header: 1,
    short_message:
      '前職の経験を活かし、エスプレッソマシンからハンドドリップ、サイフォンなどお客様に合った機械、器具をご案内し、ワンランク上の美味しいコーヒーを楽しんでいただける様お手伝いいたします。',
    notice: '',
    image_url:
      'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/barista/instructor-7.jpg',
    is_publishable: true,
    created_at: '2023-02-02T03:38:53.000000Z',
    updated_at: '2023-02-02T03:38:53.000000Z'
  },
  capsule: {
    id: 4,
    name: 'モカ ＆ キリマンジァロ',
    image_url:
      'https://ucc-coffee-creation-dev.s3.ap-northeast-1.amazonaws.com/resource/image/capsule/img_capsule-2.png',
    description:
      'アフリカで育った相性の良いコーヒー豆をブレンド。フルーティーでなめらかな味わいです。',
    map_position_id: 7,
    taste_parameter: {
      rich: 3,
      bitter: 2,
      acidity: 4
    },
    link_url: 'https://drip-pod.jp/products/417',
    order: 997,
    recommend: true,
    is_list_publishable: true,
    is_publishable: true,
    publish_start_at: '2023-01-12 00:00:00',
    publish_end_at: '2024-01-12 00:00:00',
    created_at: '2023-02-02T03:39:03.000000Z',
    updated_at: '2023-02-02T03:39:03.000000Z'
  },
  bookmark: true
};

export { data };
